$(document).ready(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('#prod-gallery').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		rtl: dirRtlFlag,
		arrows: false,
		dots: false,
		fade: true,
		autoplay: false,
		infinite: true,
		asNavFor: '#prod-gallery-navi'
	});

	$('#prod-gallery-navi').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '#prod-gallery',
		vertical: true,
		verticalSwiping: true,
		dots: false,
		arrows: false,
		focusOnSelect: true,
		infinite: true,
		autoplay: false,
		speed: 1000,
		responsive: [
			// {
			//   breakpoint: 1200,
			//   settings: {
			//     vertical: false,
			// 		verticalSwiping: false,
			// 		rtl: dirRtlFlag,
			//   }
			// },
			{
				breakpoint: 765,
				settings: {
					vertical: false,
					verticalSwiping: false,
					// arrows: false,
					rtl: dirRtlFlag,
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 480,
				settings: {
					vertical: false,
					verticalSwiping: false,
					arrows: false,
					rtl: dirRtlFlag,
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 425,
				settings: {
					vertical: false,
					verticalSwiping: false,
					arrows: false,
					rtl: dirRtlFlag,
					slidesToShow: 3,
				}
			}
		]
	});

	$('.prod-gallery-lightbox').lightGallery({
		thumbnail: true,
		selector: '.prod-gallery__img-big',
		currentPagerPosition: 'left',
	});

});